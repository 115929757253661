import {
    GET_PORTAGE,
    GET_PORTAGE_SUCCESS,
    STORE_PORTAGE,
    STORE_PORTAGE_SUCCESS,
    CHECK_COMPANY_PORTAGE,
    CHECK_COMPANY_PORTAGE_SUCCESS
} from '../action-types'

export const getPortages = (token, id) => {
    return {
        type: GET_PORTAGE,
        token: token,
        id: id,
    }
}

export const getPortagesSuccess = (data) => {
    return {
        type: GET_PORTAGE_SUCCESS,
        data: data,
    }
}

export const storePortages = (token, values) => {
    return {
        type: STORE_PORTAGE,
        token: token,
        values: values,
    }
}

export const storePortagesSuccess = (data) => {
    return {
        type: STORE_PORTAGE_SUCCESS,
        data: data,
    }
}

export const checkCompanyPortages = (token) => {
    return {
        type: CHECK_COMPANY_PORTAGE,
        token: token,
    }
}

export const checkCompanyPortagesSuccess = (data) => {
    return {
        type: CHECK_COMPANY_PORTAGE_SUCCESS,
        data: data,
    }
}
