import React, {useEffect} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {getCurrentUser} from "../../redux/actions/Auth"
import TalentLayout from '../../containers/TalentLayout'
import CompanyLayout from '../../containers/CompanyLayout'

const RequireAuth = ({children}) => {
    const dispatch = useDispatch()

    const {token, user} = useSelector(({auth}) => auth)
    let location = useLocation()

    useEffect(() => {
        if (token && !user) {
            dispatch(getCurrentUser(token))
        }
    }, [token, user])

    if (!token) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        let locationCopy = {...location}
        if (location.pathname === '/disconnect') {
            locationCopy.pathname = '/company/dashboard';
        }

        return <Navigate to="/login" state={{from: locationCopy}}/>
    }

/*    if (token && user && !user?.phone_verified) {
        return <Navigate to="/phone" state={{from: location}}/>
    }*/


    if (user?.type === 'talent') {
        if (token && user && user?.should_reset && location.pathname != '/talent/mdp') {
            return <Navigate to="/talent/mdp" state={{from: location}}/>
        }

        return <TalentLayout/>
    }

    if (user?.type === 'company') {
        if (token && user && user?.should_reset && location.pathname != '/company/mdp') {
            return <Navigate to="/company/mdp" state={{from: location}}/>
        }

        return <CompanyLayout/>
    }

    return null
}

export default RequireAuth
