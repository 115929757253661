// Import the redux-saga/effects
import {call, put, takeEvery} from 'redux-saga/effects'

// Import all action and api's
import {DELETE_FILE, GET_ALL_UPLOAD_FILES, UPLOAD_FILE, UPLOAD_FILE_EXPENSE_REPORT} from '../action-types'

// Import all api's
import {
    deleteFileRequest,
    getAllUploadFilesRequest,
    uploadFileExpenseReportRequest,
    uploadFileRequest,
} from '../api/File'

import {
    deleteFileSuccess,
    getAllUploadFilesSuccess,
    uploadFileExpenseReportSuccess,
    uploadFileContractorInvoiceSuccess,
    uploadFileSuccess,
} from '../actions/File'

import {fetchError, fetchExpired, fetchStart, fetchSuccess} from '../actions/Common'

function* getAllUploadFiles({token}) {
    try {
        //yield put(fetchStart())
        const response = yield call(getAllUploadFilesRequest, token)

        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(getAllUploadFilesSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* uploadFile({token, values}) {
    try {
        yield put(fetchStart())
        const response = yield call(uploadFileRequest, token, values)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(uploadFileSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* uploadFileExpenseReport({token, values}) {
    try {
        yield put(fetchStart())
        const response = yield call(uploadFileExpenseReportRequest, token, values)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                if (values?.type == 'expense_report') {
                    yield put(uploadFileExpenseReportSuccess(response.data))
                }
                if (values?.type == 'contractor_invoice') {
                    yield put(uploadFileContractorInvoiceSuccess(response.data))
                }
                if (values?.type == 'holiday_request') {
                    yield put(uploadFileExpenseReportSuccess(response.data))
                }
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

function* deleteFile({token, id}) {
    try {
        yield put(fetchStart())
        const response = yield call(deleteFileRequest, token, id)
        if (response.status === 200) {
            if (response.data.errors && response.data.errors.length > 0) {
                yield put(fetchError(response.data))
            } else {
                yield put(deleteFileSuccess(response.data))
                yield put(fetchSuccess())
            }
        }
        if (response.status === 401) {
            yield put(fetchExpired())
        }
        if (response.status === 500) {
            yield put(fetchError(response.data))
        }
    } catch (error) {
        console.log(error)
    }
}

// Export the saga (todo-saga)
export default function* todoSaga() {
    yield takeEvery(GET_ALL_UPLOAD_FILES, getAllUploadFiles)
    yield takeEvery(UPLOAD_FILE, uploadFile)
    yield takeEvery(UPLOAD_FILE_EXPENSE_REPORT, uploadFileExpenseReport)
    yield takeEvery(DELETE_FILE, deleteFile)
}