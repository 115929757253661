import React, {useEffect, useState} from 'react'
import {injectIntl} from "react-intl"
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import {Link, useLocation, useNavigate} from 'react-router-dom'
import {sendOtp, userSignOut, validateOtp} from "../../redux/actions/Auth";
import {useDispatch, useSelector} from "react-redux";
import Autocomplete from "@mui/material/Autocomplete";
import Snackbar from '@mui/material/Snackbar'
import MuiAlert from '@mui/material/Alert'
import {useFormik} from 'formik'
import * as yup from 'yup'
import {capitalizeFirstLetter} from "../../utils/format";
import {getLocation} from "../../redux/api/Auth";

const Login = ({intl}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate();
    let location = useLocation()
    let fromTalent = location.state?.from?.pathname || '/talent/dashboard'
    let fromCompany = location.state?.from?.pathname || '/company/dashboard'

    const {countries} = useSelector(({list}) => list)
    const {token, send_otp_success, validate_otp_success, user} = useSelector(({auth}) => auth)
    const {message, loading} = useSelector(({common}) => common)

    const [formTalent, setFormTalent] = useState({
        country_code: '',
        phone: '',
        phone_prefix: '',
        otp: ''
    })

    const [showAlert, setShowAlert] = useState(false)
    const [alertMsg, setAlertMsg] = useState('')
    const [otpSent, setOtpSent] = useState(false);
    const [open, setOpen] = useState(false);
    const [waitResend, setWaitResend] = useState(false);

    const phoneRegex = RegExp(
        /^\d*$/
      );

    const validationSchema = yup.object().shape({
        phone: yup
            .string()
            .matches(phoneRegex, intl.formatMessage({id: 'error.input.value'}))
            .required(intl.formatMessage({ id: 'this.field.is.required' })),
        country_code: yup
            .string()
            .required(intl.formatMessage({id: 'this.field.is.required'})),
    })

    const formik = useFormik({
        initialValues: formTalent,
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {
            handleSendOtp()
        },
    })

    useEffect(() => {
        getLocation(token).then((response) => {
            let data = response?.data
            if(data && data?.country_code && data?.phone_prefix) {
                setFormTalent({
                    ...formTalent,
                    country_code: data.country_code.toLowerCase(),
                    phone_prefix: data.phone_prefix
                })
            }
        })
    },[])


    useEffect(() => {
        if (send_otp_success) {
            setOtpSent(true)
        }
    }, [send_otp_success])

    useEffect(() => {
        if (token && user?.phone_verified) {
            if (user.type === 'talent') {
                navigate(fromTalent, {replace: true})
            }
            if (user.type === 'company') {
                navigate(fromCompany, {replace: true})
            }
        }
    }, [validate_otp_success, user, token])

    useEffect(() => {
        if (message === 'incorrect_otp') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'invalid.otp'}))
        }

        if (message === 'phone_exist') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'phone.exist'}))
        }

        if (message === 'error') {
            setShowAlert(true)
            setAlertMsg(intl.formatMessage({id: 'error.generic'}))
        }
    }, [message])

    const handleTextChange = (value, field) => {
        setFormTalent({
            ...formTalent,
            [field]: value
        })
    }

    const renderOtpField = () => {
        if (otpSent) {
            return (
                <section className="w-full flex-1 mt-4 ">
                    <TextField
                        label="OTP"
                        className={'w-full'}
                        value={formTalent?.code}
                        id="formatted-numberformat-input"
                        InputProps={{
                            startAdornment: <InputAdornment position="start">
                                <i className={'fa fa-lock text-secondary'}></i>
                            </InputAdornment>,
                        }}
                        onChange={(e) => handleTextChange(e?.target?.value, 'code')}
                    />
                </section>
            )
        }
    }

    const reformatCountry = (phone_prefix) => {
        let option = '';
        for (let i = 0; i < countries.length; i++) {
            if (countries[i].code === phone_prefix) {
                option = countries[i];
                break;
            }
        }

        return option
    }

    const handleSendOtp = () => {
        if (!waitResend) {
            dispatch(sendOtp(token, formTalent))
            setWaitResend(true)
            setTimeout(function() {
                setWaitResend(false)
            }, 60000)
        } else {
            setAlertMsg(intl.formatMessage({id: 'wait.resend'}))
            setShowAlert(true)
        }
    }

    const handleValidateOtp = () => {
        dispatch(validateOtp(token, formTalent))
    }

    const handleSendOtpSms = () => {
        if (!waitResend) {
            dispatch(sendOtp(token, {...formTalent, otp_type: 'sms'}))
            setWaitResend(true)
            setTimeout(function() {
                setWaitResend(false)
            }, 60000)
        } else {
            setAlertMsg(intl.formatMessage({id: 'wait.resend'}))
            setShowAlert(true)
        }
    }

    const onLogout = () => {
        dispatch(userSignOut(token))
    }

    const handleModifyNumberPhone = () => {
        setOtpSent(!otpSent)
    }

    const handleOpen = () => {
        if (!otpSent) {
          setOpen(true);
        }
    };
    
    const handleClose = () => {
        setOpen(false);
    };
    

    return (
        <div className="login-container">
            <header className="py-2 flex flex-col space-y-5 justify-center items-center">
                <img src={require('./../../assets/images/logo.png')} className="w-96" alt="Logo de Talenteum"/>

                <h1 className="xl:w-1/3 py-3 px-6 rounded-full shadow-xxl font-sans font-semibold text-green-600 xl:text-lg text-center">
                    {intl.formatMessage({id: 'validate.phone'})}
                </h1>
            </header>

            <main className="my-8 flex justify-center">
                <form
                    className="xs:w-11/12 sm:w-7/12 grid lg:grid-cols-2 md:grid-cols-1 gap-x-10 gap-y-5 place-content-center xl:grid"
                    onSubmit={formik.handleSubmit}>
                    <section className="shadow-xxl rounded-4xl md:block hidden">
                        <img src={require('./../../assets/images/login.jpg')} alt="Image de login"
                             className="rounded-4xl responsive_height"/>
                    </section>

                    <section className="shadow-xxl rounded-4xl flex justify-center flex-col p-8">
                        {
                            otpSent && !loading ?
                                <MuiAlert severity="success" className="mb-4">
                                    {intl.formatMessage({id: 'register.otp.msg'})}
                                    <br />
                                    {intl.formatMessage({id: 'enter.below.please'})}
                                </MuiAlert>
                                :
                                null
                        }
                        <section className="w-full flex-1 mt-4 ">
                            <Autocomplete
                                //value={formTalent?.phone_prefix ? reformatCountry(formTalent?.phone_prefix) : null}
                                value={formik.values.country_code ? reformatCountry(formik.values.country_code) : null}
                                disablePortal
                                id="combo-box-demo"
                                options={countries}
                                getOptionLabel={(option) => {
                                    return capitalizeFirstLetter(option?.name) + " ("+option?.phone_prefix+")"
                                }}
                                isOptionEqualToValue={(option, value) => option.code === value}
                                onChange={(e, newValue) => {
                                    setFormTalent({
                                        ...formTalent,
                                        phone_prefix: newValue?.phone_prefix,
                                        country_code: newValue?.code
                                    })
                                }}
                                className={'rounded-full'}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        label={intl.formatMessage({id: 'phone_prefix'})}
                                        error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                                        helperText={formik.touched.country_code && formik.errors.country_code}
                                        disabled = {otpSent}
                                    />
                                }
                                disableClearable={otpSent}
                                open={open}
                                onOpen={handleOpen}
                                onClose={handleClose}
                            />
                        </section>

                        <section className="w-full flex-1 mt-4 ">
                            <TextField
                                label={'Téléphone portable'}
                                className={'w-full no-arrow'}
                     
                                value={formik.values.phone || ''}
                                id="formatted-numberformat-input"
                                onChange={(e) => handleTextChange(e?.target?.value, 'phone')}
                                InputProps={{
                                    startAdornment: <InputAdornment position="start">
                                        <i className={'fa fa-phone text-secondary'}></i>
                                    </InputAdornment>,
                                }}
                                error={formik.touched.phone && Boolean(formik.errors.phone)}
                                helperText={formik.touched.phone && formik.errors.phone}
                                disabled = {otpSent}
                            />
                        </section>

                        {renderOtpField()}

                        {
                            otpSent ?
                                (
                                    <div className="flex-1 flex-col  justify-center items-end space-y-4 mt-4">
                                        <button
                                            type="button"
                                            className="px-16 py-2 bg-secondary rounded-full text-white w-full"
                                            onClick={handleValidateOtp}
                                            disabled={loading}
                                        >
                                            {intl.formatMessage({id: 'validate'})}
                                        </button>
                                        <button
                                            type="button"
                                            className="px-16 py-2 bg-orange-500 rounded-full text-white w-full"
                                            onClick={handleModifyNumberPhone}
                                        >
                                            {intl.formatMessage({id: 'change.number.phone'})}
                                        </button>
                                        <button
                                            type="submit"
                                            className={
                                            loading || waitResend ?
                                                "px-8 py-1.5 text-gray-500 border-2 border-gray-500 w-full rounded-full"
                                                :
                                                "px-8 py-1.5 text-secondary border-2 border-secondary w-full rounded-full"}
                                            //onClick={handleSendOtp}
                                            disabled={loading || waitResend}
                                        >
                                            {intl.formatMessage({id: 'resend.otp'})}
                                        </button>
                                        <button
                                            type="submit"
                                            className={
                                                loading || waitResend ?
                                                    "px-8 py-1.5 text-gray-500 border-2 border-gray-500 w-full rounded-full"
                                                    :
                                                    "px-8 py-1.5 text-secondary border-2 border-secondary w-full rounded-full"}
                                            onClick={handleSendOtpSms}
                                            disabled={loading && waitResend}
                                        >
                                            {intl.formatMessage({id: 'resend.otp.sms'})}
                                        </button>
                                    </div>
                                ) :
                                (
                                    <div className="flex-1 flex justify-center items-end mt-4">
                                        <button
                                            type="submit"
                                            className="px-16 py-2 bg-secondary rounded-full text-white"
                                            //onClick={handleSendOtp}
                                            disabled={loading}
                                        >
                                            {intl.formatMessage({id: 'send.otp'})}
                                        </button>
                                    </div>
                                )
                        }

                    </section>

                    <section></section>
{/*
I believe this should be kept but the lors
                    <section className="flex justify-center items-center space-x-4 text-xs">
                        <Link
                            className="px-4 py-1 rounded-full bg-secondary cursor-pointer text-white"
                            to={'/login'}
                            onClick={onLogout}
                        >
                            {intl.formatMessage({id: 'Logout'})}
                        </Link>
                    </section>
*/}

                </form>

            </main>

            <Snackbar
                open={showAlert} //autoHideDuration={6000}
                onClose={() => setShowAlert(false)} anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
            >
                <MuiAlert onClose={() => setShowAlert(false)} severity="error" sx={{width: '100%'}}>
                    {alertMsg}
                </MuiAlert>
            </Snackbar>
        </div>
    )
}

export default injectIntl(Login)
