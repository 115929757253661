export const pagesNav = [
    {
        name: 'recruitment',
        path: '/recruitment',
        icon: 'fa-solid fa-user-plus',
        options: [
            'Consultez le vivier talents',
            'Gérez les offres emploi',
            'Suivez les candidatures',
        ],
        subs: [
            {
                name: 'vivier_talent',
                path: '/company/search/talents',
                icon: 'fa-solid fa-magnifying-glass',
                options: [
                    'Filtrez les candidats',
                    'Comparez les talents',
                    'Ajoutez à vos favoris',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'favorite',
                path: '/company/job_offer/search/talents/favorites',
                icon: 'fa-solid fa-user-check',
                options: [
                    'Constituez vos favoris',
                    'Comparez vos favoris',
                    'Proposez des entretiens',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'job_offer',
                path: '/company/job_offer/list',
                icon: 'fa-solid fa-briefcase',
                options: [
                    'Rédigez la fiche de poste',
                    'Créez une offre d\'emploi',
                    'Publiez l\'offre d\'emploi',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'candidatures',
                path: '/company/job_offer/candidature',
                icon: 'fa-solid fa-user-tie',
                options: [
                    'Consultez les candidats',
                    'Proposez des entretiens',
                    'Retenez les meilleurs',
                ],
                button_name: "Consulter",
                break: false
            },
            /*{
                name: 'talent_personae',
                path: '/company/job_offer/ideal_candidate',
                icon: 'fa-solid fa-user-graduate',
                options: [
                    'Créez votre candidat idéal',
                    'Editez sa fiche de poste',
                    'Paramétrez vos alertes',
                ],
                button_name: "Consulter",
                break: false
            },*/
        ]
    },
    {
        name: 'portage',
        path: '/portage',
        icon: 'fa-solid fa-user-shield',
        options: [
            'Inscrivez un collaborateur',
            'Demandez son portage',
            'Gérez vos équipes',
        ],
        subs: [
            {
                name: 'port_talent_employee',
                path: '/company/port_talent_employee',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Simulez son coût mensuel',
                    'Communiquez ses coordonnées',
                    'Attribuez-lui un contrat de travail',
                ],
                button_name: "Demander",
                break: false
            },
            {
                name: 'port_talent_prestataire',
                path: '/company/port_talent_prestataire',
                icon: 'fa-solid fa-user-tag',
                options: [
                    'Simulez son coût mensuel',
                    'Communiquez ses coordonnées',
                    'Attribuez-lui un contrat de prestation',
                ],
                button_name: "Demander",
                break: false
            }
        ]
    },
    {
        name: 'parainage',
        path: '/parainage',
        icon: 'fa-solid fa-hand-holding-hand',
        options: [
            'Parrainez un talent',
            'Parrainez une entreprise',
            'Gérez vos filleuls',
        ],
        subs: [
            {
                name: 'refer_talent',
                path: '/company/refer_talent',
                icon: 'fa-solid fa-hands-holding-child',
                options: [
                    'Invitez un talent à devenir membre',
                    'Donnez-lui accès à des offres d\'emploi',
                    'Augmentez vos revenus',
                ],
                button_name: "Parrainer",
                break: false
            },
            {
                name: 'refer_entreprise',
                path: '/company/refer_entreprise',
                icon: 'fa-solid fa-hand-holding-dollar',
                options: [
                    'Invitez une entreprise à devenir membre',
                    'Donnez-lui accès à un vivier de talents',
                    'Augmentez vos revenus',
                ],
                button_name: "Parrainer",
                break: false
            },
            /*{
                name: 'my_referrals',
                path: '/company/my_referrals',
                icon: 'fa-solid fa-people-arrows',
                options: [
                    'Consultez mes filleuls',
                    'Suivez les sous-filleuls',
                    'Gérez les primes de parrainage',
                ],
                button_name: "Consulter",
                break: false
            }*/
        ]
    }
]

export const pagesAccNav = [
    {
        name: 'account',
        path: '/account',
        icon: 'fa-solid fa-circle-user',
        options: [
            'Notez vos préférences',
            'Inscrivez vos données perso',
            'Changez le mot de passe',
        ],
        subs: [
            {
                name: 'preferences',
                path: '/company/preferences',
                icon: 'fa-solid fa-asterisk',
                options: [
                    'Changez votre devise, fuseau horaire et langue',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'avatar',
                path: '/company/avatar',
                icon: 'fa-solid fa-id-badge',
                options: [
                    'Changez votre avatar',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'coordonnes',
                path: '/company/contact',
                icon: 'fa-solid fa-address-book',
                options: [
                    'Changez votre nom, prénom et fonction',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'personal_info',
                path: '/company/confidentiel',
                icon: 'fa-solid fa-id-card-clip',
                options: [
                    'Changez votre date de naissance et civilité',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'mdp',
                path: '/company/mdp',
                icon: 'fa-solid fa-lock',
                options: [
                    'Changez votre mot de passe'
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'disconnect',
                path: '/disconnect',
                icon: 'fa-solid fa-right-from-bracket',
                options: [
                    'Déconnectez-vous',
                ],
                button_name: "Déconnexion",
                break: false
            }
        ]
    },
]

export const pagesCompanyNav = [
    {
        name: 'mon_entreprise',
        path: '/company/dashboard',
        icon: 'fa-solid fa-building',
        options: [
            'Consultez vos factures',
            'Gérez vos collègues',
            'Insérez vos coordonnées',
        ],
        subs: [

            {
                name: 'society_contact',
                path: '/company/society',
                icon: 'fa-solid fa-phone',
                options: [
                    'Inserez vos coordonnées',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'juridique',
                path: '/company/document',
                icon: 'fa-solid fa-file',
                options: [
                    'Gérez vos documents',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'collegues',
                path: '/company/collab',
                icon: 'fa-solid fa-people-group',
                options: [
                    'Invitez vos collègues',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'access',
                path: '/company/group',
                icon: 'fa-solid fa-unlock',
                options: [
                    'Gérez les accès',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'company_icon',
                path: '/company/icon',
                icon: 'fa-solid fa-paint-roller',
                options: [
                    'Choississez votre icône',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'marque_employeur',
                path: '/company/values',
                icon: 'fa-solid fa-copyright',
                options: [
                    'Renseignez votre marque employeur',
                ],
                button_name: "Consulter",
                break: true
            },
            {
                name: 'my_team',
                path: '/company/my_team',
                icon: 'fa-solid fa-users-between-lines',
                options: [
                    'Gérez les membres de l\'équipe',
                ],
                button_name: "Consulter",
                break: false
            },
            {
                name: 'invoices',
                path: '/company/invoices',
                icon: 'fa-solid fa-file-invoice',
                options: [
                    'Consultez vos factures'
                ],
                button_name: "Consulter",
                break: false
            },
        ]
    },
]

export const pageLayoutMaxWidth = '1000px'